const customFieldNutritionFacts = {
  NUTRITION_FACTS_ENERGY_KJ:
    "migration_Sawade_product_vtm_nutrition_facts_energy_kj",
  NUTRITION_FACTS_ENERGY_KCAL:
    "migration_Sawade_product_vtm_nutrition_facts_energy_kcal",
  NUTRITION_FACTS_FAT: "migration_Sawade_product_vtm_nutrition_facts_fat",
  NUTRITION_FACTS_SATURATED_FAT:
    "migration_Sawade_product_vtm_nutrition_facts_saturated_fat",
  NUTRITION_FACTS_PROTEIN:
    "migration_Sawade_product_vtm_nutrition_facts_protein",
  NUTRITION_FACTS_SODIUM: "migration_Sawade_product_vtm_nutrition_facts_sodium",
} as const;

export const customFieldKeyResolver = {
  CATEGORY_SET_COLUMN: "category_set_column",
  PRODUCT_DISABLE_SHOWING_OTHER_MEDIA_ON_HOVER:
    "product_disable_showing_other_media_on_hover",
  PRODUCT_MEDIA_ON_HOVER: "product_media_on_hover",
  PRODUCT_CAN_BE_CUSTOMISED: "product_can_be_customised",
  PRODUCT_MEDIA_ON_HOVER_MIGRATION: "custom_media_mmo_1",
  GREETINGS_CARD_LINE_ONE_MAX_LENGTH: "gc_1_line_max",
  GREETINGS_CARD_LINE_TWO_MAX_LENGTH: "gc_2_line_max",
  GREETINGS_CARD_LINE_THREE_MAX_LENGTH: "gc_3_line_max",

  PRODUCT_NOT_BUYABLE_MIGRATION:
    "migration_Sawade_product_vtm_article_type_not_buyable",

  // Greetings card data to send
  GREETINGS_CARD_IS_GREETING_CARD: "is_greeting_card",
  GREETINGS_CARD_IS_BLANK: "gc_is_blank",
  GREETINGS_CARD_LINE_ONE: "gc_1_line",
  GREETINGS_CARD_LINE_TWO: "gc_2_line",
  GREETINGS_CARD_LINE_THREE: "gc_3_line",

  PRODUCT_INGREDIENTS: "migration_Sawade_product_vtm_ingredients",

  ...customFieldNutritionFacts,
} as const;
